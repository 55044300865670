/* This is a datadog helper function. This uses the CDN approach will only work if datadog is initialized via CDN */

export const addRUMError = (errorObj, context = {}) => {
  try {

    if (window && window?.DD_RUM) {
      window.DD_RUM.onReady(function() {
        console.debug('sending error for', context)
        window.DD_RUM.addError(errorObj, context);
    })
    }
  } catch (error) {
    console.error('addRUMError error', error)
  }
}