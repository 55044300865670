import { useEffect } from 'react'
import Layout from '@/components/common/default-layout'
import { getPageData } from '@/lib/contentful/page'
import { errorEventGTM } from '@/lib/gtm'
import { addRUMError } from '@/lib/datadog'
import { useRouter } from 'next/router'

const PAGE_SLUG = 'homepage'

const Index = ({ preview, page }) => {

  const router = useRouter()

  useEffect(() => {
    let d_e = new Error('404_errors')
    d_e.type = '404_errors'
    addRUMError(d_e, {
      pageUrl: `${process.env.NEXT_PUBLIC_BASE_URL}/${process.env.NEXT_PUBLIC_SITE_REGION}${router?.asPath}`
    })
  },[])

  if (typeof page.length == 'undefined') {
    const { menu, footer } = page

    //GTM
    errorEventGTM('404', 'Page data not found')
    //

    return (
      <Layout menu={menu} preview={preview} includeCart={true} footer={footer}>
        <div className="not-found-container">
          <h1 className="not-found-head">404 - Page Not Found</h1>
        </div>
      </Layout>
    )
  }

  return <div>Page data not found</div>
}

export default Index

export const getStaticProps = async ({ preview = false, locale }) => {
  const page = (await getPageData(PAGE_SLUG, preview)) ?? []
  return {
    props: {
      preview,
      page: page,
    },
  }
}
